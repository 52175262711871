@use "@angular/material" as mat;
@use "angular-material-contrast-fix";

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

@include mat.core();

$success-palette: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb69,
  500: #4caf50,
  600: #43a046,
  700: #388e3b,
  800: #2e7d31,
  900: #1b5e1f,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$primary-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90c9f9,
  300: #63b4f6,
  400: #1f87e5,
  500: $cyan-600,
  600: #1f87e5,
  700: #1a75d2,
  800: #1764c0,
  900: #1045a1,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$my-primary: mat.define-palette($primary-palette);
$my-accent: mat.define-palette($success-palette);
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: mat.define-typography-config(),
    density: -5,
  )
);

$my-theme-without-density: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.core-theme($my-theme);
@include mat.checkbox-theme($my-theme);
@include mat.autocomplete-theme($my-theme);
@include mat.progress-spinner-theme($my-theme);
@include mat.dialog-theme($my-theme);
@include mat.form-field-theme($my-theme);
@include mat.button-theme($my-theme-without-density);
@include mat.tooltip-theme($my-theme);
@include mat.input-theme($my-theme);
@include mat.select-theme($my-theme);

@include angular-material-contrast-fix.checkbox-theme($my-theme);
@include angular-material-contrast-fix.button-theme($my-theme-without-density);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": #4caf50,
  "info": $cyan-600,
  "warning": #ff9800,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

@import "../node_modules/bootstrap/scss/bootstrap.scss";

.btn-success,
.btn-secondary,
.btn-info,
.btn-warning {
  --bs-btn-color: white;
  --bs-btn-hover-color: white;
  --bs-btn-active-color: white;
}

.table-bordered > :not(caption) > * > * {
  border: 1px solid #f5f5f5;
}

.btn:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
}

.card .card-header small {
  color: #aeaeae;
}

.btn:not(.btn-link).btn-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
}

.form-select:focus {
  box-shadow: none;
}

.mat-mdc-tab-link .mdc-tab__text-label,
.mat-mdc-tab .mdc-tab__text-label {
  text-transform: uppercase;
  color: #7a7a7a !important;
}

.mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #000 !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link .mdc-tab-indicator__content--underline {
  border-color: $cyan-600 !important;
}

table thead tr th {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;

  font-weight: 500 !important;

  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;

  vertical-align: middle !important;
}

table tbody tr td {
  vertical-align: middle !important;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.flex-grow-1 {
  min-width: 0;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-color) !important;
  background-color: transparent !important;
}
