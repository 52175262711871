@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.min.css";

// font awesome 5 pro
@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/sharp-solid.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/brands.scss";

@import "assets/scss/pagination.scss";

body {
  font-family: roboto !important;
  font-size: 13px !important;
  line-height: 1.42857143 !important;
  color: #5e5e5e !important;
  background-color: #edecec !important;
}

.listbox > .button-bar {
  display: flex;
  justify-content: space-between;
}

.btn-float i {
  font-size: 23px;
}

.btn-float {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  line-height: 46px !important;
}

.card .card-header .btn-float {
  position: absolute;
  right: 25px;
  bottom: -23px;
  z-index: 1;
}

.btn-text {
  word-wrap: normal;
  font-size: 12px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.btn-icon {
  border-radius: 50%;
  width: 40px;
  line-height: 42px;
  height: 40px;
  padding: 0;
  text-align: center;
}

.search-autocomplete:focus {
  outline: none;
}

.search-autocomplete {
  border: none;
  min-width: 50px;
  border-radius: 5px;
  max-width: 300px;
  padding: 10px;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: rgb(30 60 78);
}

.search-autocomplete::placeholder {
  color: white;
}

.pointer {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

.text-frota {
  color: #f26522 !important;
}

.btn-frota {
  background-color: #f26522 !important;
  border-color: #d6591e !important;
  color: white !important;
}

.bg-frota {
  --bs-bg-opacity: 1;
  background-color: #f26522 !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: purple !important;
}

.bg-frota-subtle {
  --bs-bg-opacity: 1;
  background-color: #52220b !important;
}

.btn-frota:active {
  background-color: #be4e1a !important;
  border-color: #963e15 !important;
}

.btn-frota:hover {
  background-color: #da5a1e !important;
  border-color: #af4819 !important;
}

.sidebar-toggled {
  overflow: hidden;
  padding-right: 17px !important;
}

.sidebar-toggled .navbar {
  width: calc(100% - 17px) !important;
}

.sorting {
  background: url("assets/img/sort_both.png") no-repeat center right;
  cursor: pointer;
}

.sorting_asc {
  background: url("assets/img/sort_asc.png") no-repeat center right;
  cursor: pointer;
}

.sorting_desc {
  background: url("assets/img/sort_desc.png") no-repeat center right;
  cursor: pointer;
}

.sorting_asc_disabled {
  background: url("assets/img/sort_asc_disabled.png") no-repeat center right;
  cursor: pointer;
}

.sorting_desc_disabled {
  background: url("assets/img/sort_desc_disabled.png") no-repeat center right;
  margin-left: 2px;
  cursor: pointer;
}

.card .card-header .btn-float.dropdown-toggle:after {
  content: none;
}

.cdk-overlay-container {
  z-index: 2000 !important; // ajusta search-select para não ficar atrás do base-modal
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 999999 !important;
}
