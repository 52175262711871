.pagination {
  border-radius: 0;
}

.pagination > li {
  margin: 0 2px;
  display: inline-block;
  vertical-align: top;
}

.pagination > li > a,
.pagination > li > span {
  border-radius: 50% !important;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  z-index: 1;
  position: relative;
  cursor: pointer;
  background-clip: padding-box;
}

.pagination > li > a > .zmdi,
.pagination > li > span > .zmdi {
  font-size: 22px;
  line-height: 39px;
}

.pagination > li.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.pagination {
  display: inline-block;
}

.pagination {
  padding-left: 0;
  margin: 18px 0;
}

.pagination > li > a,
.pagination > li > span {
  float: left;
  text-decoration: none;
  color: #7e7e7e;
  background-color: #e2e2e2;
  border: 1px solid #fff;
  margin-left: -1px;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span,
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span,
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: #333;
  background-color: #d7d7d7;
  border-color: #fff;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  background-color: #00bcd4;
  border-color: #fff;
  cursor: default;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  background-color: #e2e2e2;
  border-color: #fff;
  cursor: not-allowed;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.3333333;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
